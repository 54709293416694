import React from "react"
import Layout from "../components/Layaout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"

const SecondPage = () => (
  <Layout>
    <Container>
      <SEO title="Terminos y Condiciones Generales" />
      <h1>Términos y condiciones generales</h1>
      <h2>Quiénes somos</h2>



      <p>La dirección del  Residencias Transparentes: https://www.residenciastransparentes.es.</p>



      <p>Residenicias Transparentes , es una herramienta de información sobre indicadores e indices de residencis de España. Es un proyecto apoyado por inforesidencias.com y Analistas Financieros Internacionales</p>



      <p>El objetivo de Residencias Transparentes, es facilitar el acceso a datos públicos y privados sobre el estado actual de las residencias en España</p>



      <h2>Aviso Legal</h2>



      <p>Titular: Infopenta S.L</p>



      <p>Dirección fiscal: Ronda General Mitre, 191 , 08023 Barcelona&nbsp;</p>



      <p>Contacto: gestion@inforesidencias.com Teléfono: 902.17.02.89</p>



      <p>Datos registrales: T 36815 , F 147, S 8, H M 659189, I/Aº1 (18.12.17).</p>



      <p>CIF/NIF: B64845027</p>



      <h2>Términos y Condiciones Generales </h2>



      <p>Nos gustaría que te familiarizaras con los términos y condiciones generales (T y C) que se exponen a continuación, los cuales establecen las condiciones de acceso y uso de tus tados personales.</p>



      <p>La pagina residenciastransparentes.com pone a disposición de sus usuarios datos de acceso público sobre el sector de residencias de personas mayores en España . A tal efecto:</p>







      <h2>Protección de datos</h2>



      <p>Infopenta S.L  informa al usuario de que el tratamiento de todos los datos de carácter personal se realiza de conformidad con lo dispuesto en la Ley Orgánica 15/1999, de 13 de diciembre de Protección de Datos y con el Real Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el Reglamento de desarrollo de la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter personal.”</p>



      <p>En Empresa Infopenta S.L nos preocupa especialmente garantizar la seguridad y la privacidad de los datos de nuestros clientes. Cuando un cliente se registra en nuestro servidor seguro o realiza un pedido, sus datos personales, su domicilio, y los datos relativos a su forma de pago son incorporados a nuestra base de datos, utilizándose únicamente para tramitar el pedido, así como para enviar información sobre ofertas y servicios que puedan resultar de su interés.</p>



      <p>Empresa Infopenta S.L asegura la confidencialidad de los datos aportados por nuestros clientes, y garantiza que en ningún caso serán cedidos a terceros.</p>



      <p>En cumplimiento de lo establecido en la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal, le informamos de que cuantos datos personales nos facilite serán incluidos en un fichero automatizado de datos de carácter personal, creado y mantenido bajo la responsabilidad de Infopenta S.L, NIF/CIF B64845027</p>



      <p>Infopenta S.L y debidamente inscritos en la Agencia Española de Protección de Datos (AEPD). La finalidad de dicho fichero es facilitar la tramitación de los pedidos y, en el caso de que nos haya autorizado expresamente en la casilla correspondiente, también a enviarle comunicaciones comerciales sobre productos y servicios que pueden resultar de su interés.</p>



      <p>Los clientes de Infopenta S.L podrán en todo momento ejercitar los derechos de acceso, rectificación, cancelación y oposición, comunicándolo: por correo electrónico a gestion@inforesidencias.com o por escrito a la siguiente dirección postal:</p>



      <p>Infopenta S.L</p>



      <p>Ronda General Mitre, 191. 08023 Barcelona</p>



      <p>En ambos casos se indicará el nombre y apellidos, usuario e email con el que se dio de alta.</p>



      <p>Para la utilización de nuestro sitio web es necesario la utilización de cookies. Las cookies se utilizan para analizar eluso que hacen los usuarios del sitio web. Si usted lo desea puede configurar su navegador para ser avisado en pantalla de la recepción de cookies y para impedir la instalación de cookies en el disco duro de su ordenador. Por favor, consulte las instrucciones y manuales de su navegador para ampliar esta información.</p>



      <p>Desde inforesidencias.com deseamos informarte que nuestra página web utiliza cookies para analizar la navegación de usuarios y mejorar la experiencia del usuario.</p>



      <p>A continuación, encontrarás información sobre qué son las “cookies”, qué tipos de cookies utiliza esta web, cómo puedes desactivar las cookies en tu navegador, y cómo desactivar específicamente la instalación de cookies de terceros. Si no encuentras la información específica que estás buscando, por favor dirígete a gestion@inforesidencias.com</p>



      <p>Con la publicación en el BOE del Real Decreto-ley 13/2012, de 30 de marzo, se han producido una serie de modificaciones en la Ley de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSICE) que entre otros afectan al uso de cookies y a los envíos comerciales por correo electrónico. Con este Real Decreto, por el que se transponen varias directivas europeas, España al igual que todos los estados miembros de la Unión Europea se adapta a la Directiva Europea 2009/136/CE “enmienda a la Directiva Europea sobre procesado y retención de datos y protección de la privacidad en las comunicaciones electrónicas” Artículo 22.2 de la Ley 34/2002. Los prestadores de servicios podrán utilizar dispositivos de almacenamiento y recuperación de datos en equipos terminales de los destinatarios, a condición de que los mismos hayan dado su consentimiento después de que se les haya facilitado información clara y completa sobre su utilización, en particular, sobre los fines del tratamiento de los datos, con arreglo a lo dispuesto en la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal.</p>



      <h2>Qué datos personales recogemos y por qué los recogemos</h2>



      <p></p>



      <h3>Registro</h3>



      <p>Cuando los visitantes se registra  guardamos los datos que se muestran en el formulario, así como la dirección IP del visitante y la cadena de agentes de usuario del navegador para ayudar a la detección de spam.</p>



      <h3>Cookies</h3>



      <p>Si te registras nuestro sitio puedes elegir guardar tu nombre, dirección de correo electrónico y web en cookies. Esto es para tu comodidad, para que no tengas que volver a rellenar tus datos cuando quieras volver a acceder a cualquiera de los cursos que haz comprado o cuando quieras comprar un nuevo curso. Estas cookies tendrán una duración de un año.</p>



      <p>Si tienes una cuenta y te conectas a este sitio, instalaremos una cookie temporal para determinar si tu navegador acepta cookies. Esta cookie no contiene datos personales y se elimina al cerrar el navegador.</p>



      <p>Cuando accedas, también instalaremos varias cookies para guardar tu información de acceso y tus opciones de visualización de pantalla. Las cookies de acceso duran dos días, y las cookies de opciones de pantalla duran un año. Si seleccionas «Recuérdarme», tu acceso perdurará durante dos semanas. Si sales de tu cuenta, las cookies de acceso se eliminarán.</p>





      <h3>Contenido incrustado de otros sitios web</h3>



      <p>Los datos de este sitio pueden incluir contenido incrustado (por ejemplo, vídeos, imágenes, artículos, etc.). El contenido incrustado de otras webs se comporta exactamente de la misma manera que si el visitante hubiera visitado la otra web.</p>



      <p>Estas web pueden recopilar datos sobre ti, utilizar cookies, incrustar un seguimiento adicional de terceros, y supervisar tu interacción con ese contenido incrustado, incluido el seguimiento de tu interacción con el contenido incrustado si tienes una cuenta y estás conectado a esa web.</p>



      <h2>¿Cómo puedo configurar mis preferencias?</h2>



      <p>Para permitir, conocer, bloquear o eliminar las cookies instaladas en tu equipo puedes hacerlo mediante la configuración de las opciones del navegador de internet instalado en tu ordenador. En el caso en que las bloquees, es posible que ciertos servicios que necesitan su uso no estén disponibles para ti.</p>



      <p>Algunos navegadores permiten activar un modo privado mediante el cual las cookies se borran siempre después de su visita. Dependiendo de cada navegador, este modo privado puede tener diferentes nombres. A continuación, te mostramos una lista de los navegadores más comunes y los diferentes nombres de este “modo privado”:</p>



      <p>Internet Explorer 8 y superior; InPrivate</p>



      <p>Safari 2 y superior; Navegación Privada</p>



      <p>Opera 10.5 y superior; Navegación Privada</p>



      <p>FireFox 3.5 y superior; Navegación Privada</p>



      <p>Google Chrome 10 y superior; Incógnito</p>



      <p>Atención: Por favor, lee atentamente la sección de ayuda de tu navegador para conocer más acerca de cómo activar el “modo privado”. Podrás seguir visitando nuestra web aunque tu navegador esté en “modo privado”, si bien, tu navegación por nuestra web puede no ser óptima y algunas utilidades como la cesta de la compra pueden no funcionar correctamente.</p>



      <p>Información actualizada el 7-09-2021</p>



      <h2>Cuánto tiempo conservamos tus datos</h2>



      <p>De los usuarios que se registran en nuestra web (si los hay), también almacenamos la información personal que proporcionan en el registro. Todos los usuarios pueden ver, editar o eliminar su información personal en cualquier momento (excepto que no pueden cambiar su nombre de usuario). Los administradores de la web también pueden ver y editar esa información.</p>



      <h2>Qué derechos tienes sobre tus datos</h2>



      <p> Puedes solicitar que eliminemos cualquier dato personal que tengamos sobre ti. Esto no incluye ningún dato que estemos obligados a conservar con fines administrativos, legales o de seguridad.</p>



      <h1>Seguridad</h1>



      <p>El sitio web utiliza técnicas de seguridad de la información generalmente aceptada en la industria, tales como firewalls, procedimientos de control de acceso y mecanismos criptográficos, todo ello con el objeto de evitar el acceso no autorizado de datos y el posible robo de los mismos. Para lograr estos fines, el usuario/cliente acepta que el prestador obtenga datos para efecto de la correspondiente autenticación de los controles de acceso.</p>












      <h2>¿Dónde puede obtener más información?</h2>



      <p>Si deseas ampliar información sobre temas de seguridad puedes recurrir a info[arroba]residenciastransparentes.com</p>




    </Container>
  </Layout>
)

export default SecondPage
